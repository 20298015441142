import React, { useState } from 'react';
import { Box, Stack, Autocomplete, TextField, Typography, useTheme } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import PlayerTradeItem from './PlayerTradeItem';
import TotalTradeValue from './TotalTradeValue';

const TradePackageBuilder = ({
  playerRankings,
  teamNumber,
  setTeamPlayers,
  teamPlayers,
  teamValue,
  depreciationValue,
  success
}) => {

  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const [dropdownToggle, setDropdownToggle] = useState(true);

  const theme = useTheme();

  const responsiveFontSize = {
    fontSize: {
      xs: '0.75rem',
      sm: '0.875rem',
      md: '1rem',
      lg: '1.125rem',
    },
    fontFamily: 'Avenir, Arial, sans-serif'
  };

  const handleAddPlayerToTeam = (player, team) => {
    setDropdownToggle(!dropdownToggle);
    if (player && (player.assetType === "PICK" || !teamPlayers.some(p => p.name === player.name))) {
      setTeamPlayers(prev => [...prev, player]);
    }
  };

  // Adjust handleDeletePlayerFromTeam:
  const handleDeletePlayerFromTeam = (playerToDelete, indexToDelete) => {
    setTeamPlayers((prevPlayers) =>
      prevPlayers.filter((_, i) => i !== indexToDelete)
    );
  };

  const customFilterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option.name,
    limit: 10
  });

  const loading = !playerRankings || playerRankings.length === 0;

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      gap: 1,
      width: '100%',
      minWidth: '300px',
      [theme.breakpoints.down('md')]: {
        minWidth: '100%',
      },
    }}>
      <Autocomplete
        options={playerRankings}
        loading={loading}
        open={openAutocomplete}
        onClose={() => setOpenAutocomplete(false)}
        onInputChange={(event, value) => {
          setOpenAutocomplete(value.length > 0);
        }}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        renderInput={(params) => (
          <TextField
            {...params}
            label={`Team ${teamNumber} receives`}
            InputLabelProps={{
              sx: {
                fontFamily: 'Avenir, Arial, sans-serif',
                fontWeight: 500
              }
            }}
          />
        )}
        onChange={(event, newValue) => handleAddPlayerToTeam(newValue, teamNumber)}
        sx={{ width: '100%', marginLeft: 0 }}
        key={`teamPlayers-${teamNumber}-${dropdownToggle}`}
        filterOptions={customFilterOptions}
        renderOption={(props, option, { inputValue }) => {
          const { key, ...otherProps } = props;
          const parts = option.name.split(new RegExp(`(${inputValue})`, 'gi')); // Split text into matching and non-matching parts

          return (
            <li key={key} {...otherProps}>
              <Stack direction="row" spacing={2} alignItems="center" sx={{ width: '100%' }}>
                <Typography variant="subtitle2" component="div" sx={{ flexGrow: 1, ...responsiveFontSize }}>
                  {parts.map((part, index) =>
                    part.toLowerCase() === inputValue.toLowerCase() ? (
                      <strong key={index}>{part}</strong>
                    ) : (
                      part
                    )
                  )}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ ...responsiveFontSize }}>
                  {option.value.toLocaleString()}
                </Typography>
              </Stack>
            </li>
          );
        }}
      />
      {teamPlayers.length > 0 && 
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          width: '100%', 
          borderRadius: '8px', 
          padding: '16px', 
          marginTop: '8px',
          boxSizing: 'border-box',
          boxShadow: success ? '0 0 20px rgba(0, 128, 0, 0.3)' : '0 0 20px rgba(255, 0, 0, 0.2)'
        }}>
          {teamPlayers.map((player, index) => (
            <PlayerTradeItem key={`${player.name}-${teamNumber}-${index}`} player={player} onDelete={() => handleDeletePlayerFromTeam(player, index)} />
          ))}
          <TotalTradeValue totalValue={teamValue} depreciationValue={depreciationValue} />
        </Box>
      }
    </Box>
  );
  
};

export default TradePackageBuilder;
