import React, { useState } from 'react';
import { AppBar, Toolbar, ListItem, Box, Typography, IconButton, useMediaQuery, useTheme, Fade } from '@mui/material';
import SidebarMobile from '../../components/Sidebar/SidebarMobile';
import SwipeVerticalIcon from '@mui/icons-material/SwipeVertical';
import ListIcon from '@mui/icons-material/List';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import InfoIcon from '@mui/icons-material/Info';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import GroupIcon from '@mui/icons-material/Group';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom'; // Import useLocation
import colors from '../../config/colors';
import logoImage from '../../assets/fullNameLogo.png';
import SelectionCounter from '../SelectionCounter/SelectionCounter';

const TopbarLandingPage = ({ selectionsCount, error }) => {
  const [dropdown, setDropdown] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Detect screen size

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleMobileSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  const handleLogoClick = () => {
    setIsSidebarOpen(false);
  };

  const handleDropdownToggle = (name) => {
    setDropdown((prev) => (prev === name ? null : name));
  };

  const dynastyItems = [
    { path: '/picker', text: 'Picker', icon: <SwipeVerticalIcon /> },
    { path: '/rankings', text: 'Rankings', icon: <ListIcon /> },
    { path: '/calculator', text: 'Trade Calculator', icon: <ShuffleIcon /> },
    { path: '/league', text: 'League Power Rankings', icon: <GroupIcon /> },
    { path: '/activity', text: 'Activity Feed', icon: <DynamicFeedIcon /> }
  ];

  const generalItems = [
    { path: '/faq', text: 'FAQ', icon: <InfoIcon /> },
  ];


  const renderMenuItems = (items) => (
    <Box
      className="custom-dropdown"
      sx={{
        marginTop: '23px',
        padding: '5px',
        backgroundColor: 'white',
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: `0px 10px 40px ${colors.secondary500}60`, // Longer, less opaque shadow on the bottom and sides
        minWidth: '150px',
        position: 'absolute',
        zIndex: 1300
      }}
    >
      {items.map(({ path, text, icon }) => (
        <Link
          key={text}
          to={path}
          className="dropdown-item"
          style={{ textDecoration: 'none' }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '10px',
              backgroundColor: 'transparent',
              color: colors.grey500,
              transition: 'all 0.3s ease-in-out',
              '&:hover': {
                backgroundColor: colors.secondary300,
                transform: 'scale(1.08)',
              },
            }}
          >
            <Box sx={{ color: colors.grey500 }}>{icon}</Box>
            <Typography sx={{ marginLeft: '10px', fontFamily: 'Futura, sans-serif' }}>
              {text}
            </Typography>
          </Box>
        </Link>
      ))}
    </Box>
  );

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        backgroundColor: 'white',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: `0px 30px 60px ${colors.secondary500}70`,
        height: '76px'
      }}
    >
        {isSidebarOpen ? <SidebarMobile /> : null}
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        {/* Logo */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '5px',
          }}
        >
          <ListItem
            button
            component={Link}
            to="/"
            key="LandingPage-topbar"
            sx={{
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
            onClick={handleLogoClick}
          >
            <img src={logoImage} alt="Logo" style={{ maxHeight: 50 }} />
          </ListItem>
        </Box>

        {/* Conditionally Render MenuIcon for Small Screens */}
        {isSmallScreen ? (
          <IconButton
          edge="start"
          color="black"
          aria-label="menu"
          sx={{ p: 2, "& .MuiSvgIcon-root": { fontSize: "2rem" } }}
          onClick={toggleMobileSidebar}
        >
          <Fade in={!isSidebarOpen} timeout={300}>
            <MenuIcon style={{ position: 'absolute' }} />
          </Fade>
          <Fade in={isSidebarOpen} timeout={300}>
            <CloseIcon style={{ position: 'absolute' }} />
          </Fade>
        </IconButton>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: 2,
              flexGrow: 1,
              padding: '10px'
            }}
          >
            {/* Dynasty Dropdown */}
            <Box sx={{ position: 'relative' }}>
              <IconButton
                onClick={() => handleDropdownToggle('dynasty')}
                color="inherit"
                sx={{
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <Typography
                  sx={{
                    color: colors.grey500,
                    fontFamily: 'Futura, sans-serif',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  Dynasty
                </Typography>
                <ExpandMoreIcon
                  sx={{
                    color: colors.grey500,
                    transition: 'transform 0.3s ease',
                    transform: dropdown === 'dynasty' ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                />
              </IconButton>
              {dropdown === 'dynasty' && renderMenuItems(dynastyItems)}
            </Box>

            {/* General Dropdown */}
            <Box sx={{ position: 'relative' }}>
              <IconButton
                onClick={() => handleDropdownToggle('general')}
                color="inherit"
                sx={{
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <Typography
                  sx={{
                    color: colors.grey500,
                    fontFamily: 'Futura, sans-serif',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  General
                </Typography>
                <ExpandMoreIcon
                  sx={{
                    color: colors.grey500,
                    transition: 'transform 0.3s ease',
                    transform: dropdown === 'general' ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                />
              </IconButton>
              {dropdown === 'general' && renderMenuItems(generalItems)}
            </Box>
          </Box>
        )}
      </Toolbar>
      <SelectionCounter selectionsCount={selectionsCount} error={error} topMargin="76px" />
    </AppBar>
  );
};

export default TopbarLandingPage;