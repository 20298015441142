import React from 'react';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis } from 'recharts';
import { Box } from '@mui/material';
import colors from '../../../config/colors';

const MiniChart = ({ valueHistory, defaultValue }) => {
  // Preprocess the valueHistory to handle null entries
  const processedHistory = [];
  valueHistory.forEach((entry) => {
    if (entry && entry.value !== null) {
      processedHistory.push({ value: entry.value });
    }

  });

  // Transform the data for the chart
  const chartData = processedHistory.map((entry, index) => ({
    index,
    value: entry.value,
  }));

  const minY = Math.min(...chartData.map((data) => data.value));
  const maxY = Math.max(...chartData.map((data) => data.value));

  // Ensure a minimum range of 200
  const range = maxY - minY;
  const padding = range < 400 ? (400 - range) / 2 : 0;

  const adjustedMinY = minY - padding - 50;
  const adjustedMaxY = maxY + padding + 50;


  // Determine the line color based on the trend
  const firstValue = chartData[0]?.value || defaultValue;
  const lastValue = chartData[chartData.length - 1]?.value || defaultValue;
  const lineColor = lastValue >= firstValue ? colors.successMain : colors.primary400;
  const fillColor = lastValue >= firstValue ? colors.successLight : colors.primary300;

  // Generate unique gradient IDs
  const uniqueId = Math.random().toString(36).substr(2, 9);
  const lineGradientId = `lineGradient-${uniqueId}`;
  const fillGradientId = `fillGradient-${uniqueId}`;

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart data={chartData} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
          {/* Define dynamic gradients */}
          <defs>
            <linearGradient id={lineGradientId} x1="0" y1="0" x2="1" y2="0">
              <stop offset="0%" stopColor={lineColor} stopOpacity={0} />
              <stop offset="15%" stopColor={lineColor} stopOpacity={1} />
              <stop offset="85%" stopColor={lineColor} stopOpacity={1} />
              <stop offset="100%" stopColor={lineColor} stopOpacity={0} />
            </linearGradient>
            <linearGradient id={fillGradientId} x1="0" y1="0" x2="1" y2="0">
              <stop offset="0%" stopColor={fillColor} stopOpacity={0} />
              <stop offset="15%" stopColor={fillColor} stopOpacity={0.5} />
              <stop offset="85%" stopColor={fillColor} stopOpacity={0.5} />
              <stop offset="100%" stopColor={fillColor} stopOpacity={0} />
            </linearGradient>
          </defs>

          {/* Apply dynamic gradient colors */}
          <XAxis dataKey="index" hide />
          <YAxis domain={[adjustedMinY, adjustedMaxY]} hide />
          <Area
            type="monotone"
            dataKey="value"
            stroke={`url(#${lineGradientId})`}
            fill={`url(#${fillGradientId})`}
            strokeWidth={2}
            isAnimationActive={false}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};


export default MiniChart;
