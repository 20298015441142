import React, { useState } from 'react';
import { Box, Stack, Typography, Paper, Tooltip, IconButton, ClickAwayListener } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import colors from '../../config/colors';

const TotalTradeValue = ({ totalValue, depreciationValue }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipToggle = () => {
    setOpen((prev) => !prev);
  };
  
  const responsiveFontSize = {
    fontSize: {
      xs: '0.9rem',
      sm: '1rem',
      md: '1rem',
      lg: '1.125rem',
    },
    fontFamily: 'Avenir, Arial, sans-serif',
  };

  const responsiveFontSizePenalty = {
    fontSize: {
      xs: '0.8rem',
      sm: '0.9rem',
      md: '0.9rem',
      lg: '1rem',
    },
    fontFamily: 'Avenir, Arial, sans-serif',
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        mb: 0,
        width: '100%',
        border: 'none',
        borderBottom: '0px solid rgba(0, 0, 0, 0.12)',
      }}
    >
      <Box sx={{ p: 1, pb: 0 }}>
        {depreciationValue !== 0 && (
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            sx={{ width: '100%', pb: 1.5 }}
          >
            <Typography
              variant="body1"
              color="text.secondary"
              component="div"
              sx={{ flexGrow: 1, ...responsiveFontSizePenalty }}
            >
              Package adjustment
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                  title={
                    <Typography sx={{ fontSize: { xs: '.7rem', sm: '.8rem', md: '.9rem' }, fontFamily: 'Avenir, Arial, sans-serif' }}>
                      Applies a deduction when combining lower-value players to acquire a high-value player
                    </Typography>
                  }                  
                  placement="top"
                  open={open}
                  onClose={handleTooltipClose}
                  disableFocusListener
                    disableHoverListener
                    disableTouchListener
                >
                  <IconButton size="small" onClick={handleTooltipToggle}>
                    <InfoOutlinedIcon fontSize='inherit' />
                  </IconButton>
                </Tooltip>
              </ClickAwayListener>
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={responsiveFontSizePenalty}
            >
              -{depreciationValue.toLocaleString()}
            </Typography>
          </Stack>
        )}
        <Stack direction="row" spacing={2} alignItems="center" sx={{ width: '100%' }}>
          <Typography
            variant="body1"
            component="div"
            sx={{
              color: colors.secondary550,
              flexGrow: 1,
              fontWeight: 'bold',
              ...responsiveFontSize,
            }}
          >
            Total value
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: colors.secondary550,
              fontWeight: 'bold',
              ...responsiveFontSize,
            }}
          >
            {totalValue.toLocaleString()}
          </Typography>
        </Stack>
      </Box>
    </Paper>
  );
};

export default TotalTradeValue;
