import React, { useEffect, useState } from 'react';
import { CircularProgress, Alert, Box, Button, Typography, Stack, Paper, Grid } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';
import { generateFourSelection, rankFourPlayers } from '../../api/apiService';
import Title from '../Title/Title';
import HeadWrapper from '../HeadWrapper/HeadWrapper';
import logo from '../../assets/Picker.png';
import colors from '../../config/colors';

const Picker = () => {
  const [players, setPlayers] = useState([]);
  const [favored1, setFavored1] = useState(null);
  const [favored2, setFavored2] = useState(null);
  const [favored3, setFavored3] = useState(null);
  const [favored4, setFavored4] = useState(null);
  const [difficulty, setDifficulty] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [errorResponse, setErrorResponse] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const generateSelection = () => {
      const payload = { difficulty };
      generateFourSelection(payload)
        .then((response) => {
          setPlayers(response.data);
          setFavored1(null);
          setFavored2(null);
          setFavored3(null);
          setFavored4(null);
          setErrorResponse(false);
          setRefreshLoading(false);
        })
        .catch((error) => {
          setErrorResponse(true);
          setRefreshLoading(false);
          console.error('There was an error fetching the player data', error);
        });
    };
    generateSelection();
  }, [difficulty, refresh]);

  const handleSubmit = () => {
    setSubmitLoading(true);
    const payload = { favored1, favored2, favored3, favored4 };
    rankFourPlayers(payload)
      .then((response) => {
        console.log(response);
        setDifficulty(1);
        setRefresh(!refresh);
        setErrorResponse(false);
        setSubmitLoading(false);
      })
      .catch((error) => {
        setErrorResponse(true);
        setSubmitLoading(false);
        console.error('There was an error sending the player rankings', error);
      });
  };

  const handleRefresh = () => {
    setRefreshLoading(true);
    setDifficulty((prevDifficulty) => prevDifficulty + 1);
  };

  const selectPlayer = (selectedPlayer, index) => {
    [setFavored1, setFavored2, setFavored3, setFavored4].forEach((setFavored, i) => {
      if ([favored1, favored2, favored3, favored4][i] === selectedPlayer) setFavored(null);
    });
    [setFavored4, setFavored3, setFavored2, setFavored1][index](selectedPlayer);
  };

  const renderPlayerButton = (player) => {
    const isSelected = [favored4, favored3, favored2, favored1].map((f) => f?.id === player.id);
    const buttonValues = [20, 40, 60, 80];

    return (
      <Paper key={player.id} elevation={1} sx={{ p: 2, border: `1px solid ${colors.greyDark}`, backgroundColor: 'white' }}>
        <Grid container spacing={2} sx={{ mb: 1, alignItems: 'center' }}>
          <Grid item xs={6}>
            <Typography variant="h6" sx={{ fontFamily: 'Avenir, Arial, sans-serif', fontSize: isSmallScreen ? '.95rem' : '1.25rem' }}>
              {player.name}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1" sx={{ fontFamily: 'Avenir, Arial, sans-serif', fontSize: isSmallScreen ? '0.85rem' : '1rem' }} color="text.secondary">
              {player.positions && player.positions.join(', ')}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1" sx={{ fontFamily: 'Avenir, Arial, sans-serif', fontSize: isSmallScreen ? '0.85rem' : '1rem' }} color="text.secondary">
              {player.team}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1" sx={{ fontFamily: 'Avenir, Arial, sans-serif', fontSize: isSmallScreen ? '0.85rem' : '1rem' }} color="text.secondary">
              {player.age}
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          {buttonValues.map((value, index) => (
            <Button
              key={`${player.id}-${value}`}
              variant={isSelected[index] ? 'contained' : 'outlined'}
              onClick={() => selectPlayer(player, index)}
              sx={{
                flexGrow: 1,
                mx: 0.2,
                fontSize: isSmallScreen ? '0.7rem' : '1rem',
                fontFamily: 'Avenir, Arial, sans-serif'
              }}
            >
              {value}
            </Button>
          ))}
        </Box>
      </Paper>
    );
  };

  const isSubmitDisabled = ![favored1, favored2, favored3, favored4].every(Boolean);

  return (
    <Box sx={{ p: .6 }}>
      <HeadWrapper title="Dynasty Baseball Picker" description="Choose between players and draft picks to influence our rankings." image={logo} />
      {errorResponse && (
        <Alert severity="error" sx={{ marginBottom: 2, fontFamily: 'Avenir, Arial, sans-serif' }}>
          Error retrieving Picker options. Please check your internet connection or try again later.
        </Alert>
      )}
      <Title
        pageName="Player Picker"
        extraText="Dynasty, head-to-head, standard points scoring. Select a unique grade for each player. Each grade can only be used once."
        showPickerLink={false}
        showRankingsLink={true}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%' }}>
        <Stack spacing={2} sx={{ width: '100%', maxWidth: '600px', mx: 'auto' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Typography variant="body1" sx={{ fontFamily: 'Avenir, Arial, sans-serif', fontSize: isSmallScreen ? '0.85rem' : '1rem' }}>
              20 = Least Valuable
            </Typography>
            <Typography variant="body1" sx={{ fontFamily: 'Avenir, Arial, sans-serif', fontSize: isSmallScreen ? '0.85rem' : '1rem' }}>
              80 = Most Valuable
            </Typography>
          </Box>
          {players.map(renderPlayerButton)}
          <Stack spacing={1} sx={{ width: '100%', pb: 2 }}>
            <Button variant="contained" onClick={handleSubmit} sx={{ width: '100%' }} disabled={isSubmitDisabled || submitLoading}>
              {submitLoading ? <CircularProgress size={24} color="inherit" /> : <Typography sx={{ fontSize: isSmallScreen ? '.9rem' : '1rem' }}>Submit Rankings</Typography>}
            </Button>
            <Button variant="outlined" onClick={handleRefresh} sx={{ width: '100%' }} disabled={refreshLoading}>
              {refreshLoading ? <CircularProgress size={24} color="inherit" /> : <Typography sx={{ fontSize: isSmallScreen ? '.9rem' : '1rem' }}>Refresh Players</Typography>}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default Picker;
