import './App.css';
import React from 'react';
import { Navigate } from "react-router-dom";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '@mui/material/styles';
import LandingPage from './containers/LandingPage/LandingPage';
import Rankings from './components/Rankings/Rankings';
import usePlayerRankings from './hooks/usePlayerRankings';
import useSelectionsCount from './hooks/useSelectionsCount';
import TwoPlayerModal from './components/Picker/PickerModal/TwoPlayerModal';
import Picker from './components/Picker/Picker';
import TradeCalculator from './components/TradeCalculator/TradeCalculator';
import ActivityPage from './components/ActivityPage/ActivityPage';
import Layout from './containers/Layout/Layout';
import FAQ from './components/FAQ/FAQ';
import LeagueView from './components/LeagueView/LeagueView';
import TeamView from './components/LeagueView/Team/TeamView';
import PlayerPage from './components/PlayerPage/PlayerPage';
import CookiesPopup from './components/CookiesPopup/CookiesPopup';
import PrivacyPolicyPage from './containers/PrivacyPolicyPage/PrivacyPolicyPage';
import TermsOfUsePage from './containers/TermsOfUsePage/TermsOfUsePage';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import theme from './config/theme';
import useAllPlayerSelections from './hooks/useAllPlayerSelections';
import { LeagueProvider } from './hooks/LeagueContext';

function App() {
  const { players, playersError, lastUpdated } = usePlayerRankings();
  const { selections, selectionsError } = useAllPlayerSelections();
  const { selectionsCount, selectionsCountError } = useSelectionsCount();

  return (
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <CookiesPopup />
        <BrowserRouter>
        <ScrollToTop />
          <Routes>
            <Route path="/" element={
              <LandingPage players={players} playersError={playersError} selectionsCount={selectionsCount} selectionsCountError={selectionsCountError} />
              } />
            <Route path="picker" element={
              <Layout selectionsCount={selectionsCount} error={selectionsCountError} >
                <Picker />
              </Layout>
            } />
            <Route path="rankings" element={
              <Layout selectionsCount={selectionsCount} error={selectionsCountError}>
                <Rankings players={players} error={playersError} lastUpdated={lastUpdated} />
              </Layout>
            } />
            <Route path="calculator" element={
              <Layout selectionsCount={selectionsCount} error={selectionsCountError}>
                <TradeCalculator playerRankings={players} error={playersError} />
              </Layout>
            } />
            <Route path="activity" element={
              <Layout selectionsCount={selectionsCount} error={selectionsCountError}>
                <ActivityPage selections={selections} error={selectionsError} />
              </Layout>
            } />
            <Route element={
              <Layout selectionsCount={selectionsCount} error={selectionsCountError}>
                <LeagueProvider>
                  <Outlet />
                </LeagueProvider>
              </Layout>
            }>
              <Route path="league/:leagueId?/rankings?" element={<LeagueView />} />
              <Route path="league/:leagueId/team/:teamId?" element={<TeamView />} />
            </Route>
            <Route path="faq" element={
              <Layout selectionsCount={selectionsCount} error={selectionsCountError}>
                <FAQ />
              </Layout>
            } />
            <Route path="player/:id" element={
              <Layout selectionsCount={selectionsCount} error={selectionsCountError}>
                <PlayerPage />
              </Layout>
            } />
            <Route path="privacy-policy" element={
              <Layout selectionsCount={selectionsCount} error={selectionsCountError}>
                <PrivacyPolicyPage />
              </Layout>
            } />
            <Route path="terms-of-service" element={
              <Layout selectionsCount={selectionsCount} error={selectionsCountError}>
                <TermsOfUsePage />
              </Layout>
            } />
            <Route
              path="*"
              element={
                <Navigate to="/" replace /> // Redirect to LandingPage
              }
            />
          </Routes>
          <TwoPlayerModal />
        </BrowserRouter>
      </HelmetProvider>
    </ThemeProvider>
  );
}

export default App;
