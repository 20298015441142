import React from 'react';
import { Box, Typography, Paper, Stack } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import colors from '../../config/colors';

// Define reusable styles
const styles = {
  icon: {
    width: { xs: 18, sm: 24, md: 30 },
    height: { xs: 18, sm: 24, md: 30 },
    borderRadius: '20%',
    backgroundColor: colors.primary400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: { xs: '0.65rem', sm: '.8rem', md: '.9rem' },
    color: 'white',
    fontWeight: 600,
    flexShrink: 0,
    zIndex: 100,
    fontFamily: 'Avenir, Arial, sans-serif',
  },
  name: {
    fontWeight: 600,
    color: 'black',
    fontSize: { xs: '0.65rem', sm: '.8rem', md: '1.1rem' },
    fontFamily: 'Avenir, Arial, sans-serif',
    paddingLeft: { xs: '7px', sm: '10px', md: '15px' },
  },
  value: {
    fontSize: { xs: '0.65rem', sm: '.8rem', md: '1.1rem' },
    textAlign: 'right',
    fontFamily: 'Avenir, Arial, sans-serif',
    fontWeight: 'bold',
  },
  row: {
    py: { xs: 0.8, sm: 1, md: 1.2 },
    px: { xs: 0.8, sm: 1, md: 1.2 },
    border: '1px solid',
    backgroundColor: colors.secondary100,
    borderColor: colors.secondary500,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1,
    marginLeft: {xs: '-17px', sm: '-20px', md: '-24px'},
  },
};

// Keyframes for the fade-in animation
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AnimatedContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'fadeOpacity' && prop !== 'delay',
})(({ delay }) => ({
  animation: `${fadeIn} 0.5s ease-out`,
  animationDelay: `${delay}s`,
  animationFillMode: 'forwards',
  opacity: 0, // Ensure it starts invisible
  display: 'flex',
  alignItems: 'center',
  gap: '8px', // Space between the icon and the row
}));

// Faded additional items will have a separate keyframe to manage fading with stagger
const fadeInWithOpacity = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10%);
  }
  to {
    opacity: var(--final-opacity, 1);
    transform: translateY(0);
  }
`;

const FadedContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'fadeOpacity' && prop !== 'delay',
})(({ delay, fadeOpacity }) => ({
  animation: `${fadeInWithOpacity} 0.5s ease-out`,
  animationDelay: `${delay}s`,
  animationFillMode: 'forwards',
  opacity: 0, // Start invisible
  '--final-opacity': fadeOpacity, // Use variable for final opacity
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}));

const RankingsTopN = ({ players, count, effectEnabled = false }) => {
  const primaryDelayBase = 0.2; // Base delay between primary row animations
  const fadedRowDelayBase = 0.2; // Delay between faded rows starting
  const totalPrimaryDuration = count * primaryDelayBase; // Total duration of primary animations

  return (
    <Stack spacing={{ xs: 1.5, sm: 1.5, md: 2 }} sx={{ mx: 'auto', width: '100%' }}>

      {/* Primary Rows */}
      {players.slice(0, count).map((player, index) => {
        const delay = effectEnabled ? index * primaryDelayBase : 0; // Stagger animation if effectEnabled is true
        return (
          <AnimatedContainer key={player.id} delay={delay}>
            {/* Circular Icon */}
            <Box sx={styles.icon}>{index + 1}</Box>

            {/* Animated Row */}
            <Paper sx={styles.row}>
              <Typography sx={styles.name}>{player.name}</Typography>
              <Box>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    ...styles.value,
                    color:
                      player.value === 10000
                        ? colors.secondary600
                        : 'text.secondary',
                  }}
                >
                  {player.value.toLocaleString()}
                </Typography>
              </Box>
            </Paper>
          </AnimatedContainer>
        );
      })}

      {/* Faded Additional Items */}
      {players.slice(count, count + 3).map((player, index) => {
        const delay = effectEnabled
          ? totalPrimaryDuration + index * fadedRowDelayBase
          : 0; // Start faded rows after primary rows are done
        const fadeOpacity = 1 - (index + 1) * 0.3; // Gradual fade
        return (
          <FadedContainer
            key={`faded-${player.id}`}
            delay={delay}
            fadeOpacity={fadeOpacity}
          >
            <Box sx={styles.icon}>{count + index + 1}</Box>
            <Paper sx={styles.row}>
              <Typography sx={styles.name}>{player.name}</Typography>
              <Box>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    ...styles.value,
                    color:
                      player.value === 10000
                        ? colors.secondary600
                        : 'text.secondary',
                  }}
                >
                  {player.value.toLocaleString()}
                </Typography>
              </Box>
            </Paper>
          </FadedContainer>
        );
      })}
    </Stack>
  );
};

export default RankingsTopN;
