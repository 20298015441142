import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Paper, Grid, Box, Tooltip, useMediaQuery } from '@mui/material';
import { alpha } from '@mui/material/styles';
import colors from '../../config/colors';

const styles = {
  icon: {
    width: { xs: '16%', sm: '13%', md: '12%' },
    height: '70%',
    borderRadius: '20%',
    backgroundColor: colors.primary400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: { xs: '0.6rem', sm: '.8rem', md: '.95rem' },
    color: 'white',
    fontWeight: 600,
    flexShrink: 0,
    zIndex: 100,
    fontFamily: 'Avenir, Arial, sans-serif',
    position: 'relative',
  },
  row: {
    border: '1px solid',
    backgroundColor: colors.secondary100,
    borderColor: colors.secondary500,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1,
    marginLeft: { xs: '-10px', sm: '-12px', md: '-14px' },
    position: 'relative',
    zIndex: 99,
    width: '100%',
    minWidth: 0,
  },
  text: {
    fontWeight: 600,
    color: 'black',
    fontSize: { xs: '0.6rem', sm: '.8rem', md: '.95rem' },
    fontFamily: 'Avenir, Arial, sans-serif',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
  },
  gridRow: {
    marginBottom: { xs: '6px', sm: '10px', md: '14px' },
    '&:last-child': {
      marginBottom: '0px',
    },
  },
  tierBreak: {
    borderTop: `1px solid grey`,
    marginTop: { xs: '10px', sm: '14px', md: '18px' },
    marginBottom: { xs: '2px', sm: '6px', md: '8px' },
    position: 'relative',
    paddingTop: { xs: '16px', sm: '18px', md: '20px' },
    paddingBottom: { xs: '8px', sm: '10px', md: '12px' },
  },
  tierLabel: {
    position: 'absolute',
    top: '-8px',
    left: '50%',
    transform: 'translateX(-50%)',
    fontSize: { xs: '0.6rem', sm: '0.7rem', md: '0.8rem' },
    fontWeight: 400,
    color: 'grey',
    backgroundColor: 'white',
    padding: '0 4px',
    fontFamily: 'Avenir, Arial, sans-serif',
  },
  positionContainer: {
    minWidth: 0,
    width: '100%',
  },
  positionCard: {
    width: '100%',
    position: 'relative',
    '&::before': {
      content: '""',
      display: 'block',
      paddingTop: { xs: '100%', sm: '80%', md: '70%' },
    },
    '& > *': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
};

const PositionRankingsTable = ({ teams, leagueId, includePicks = true }) => {
  const rowRefs = useRef(teams.flat().map(() => React.createRef()));
  const tierRefs = useRef(teams.flat().map(() => React.createRef()));
  const tierLabelRefs = useRef(teams.flat().map(() => React.createRef()));
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const isMobile = useMediaQuery('(max-width: 700px)');

  const handleTooltipToggle = () => {
    setTooltipOpen((prev) => !prev);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      // Request animation frame for smooth updates
      window.requestAnimationFrame(() => {
        updateFadeStyles(); // Update row styles
        updateTierFadeStyles(); // Update tier styles
        updateTierLabelFadeStyles();
      });
    };
  
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const updateFadeStyles = () => {
    rowRefs.current.forEach((rowRef) => {
      if (rowRef.current) {
        const fadeStyle = calculateFadeStyle(rowRef);
        Object.assign(rowRef.current.style, fadeStyle);
      }
    });
  };

  const updateTierFadeStyles = () => {
    tierRefs.current.forEach((tierRef) => {
      if (tierRef.current) {
        const { borderTop } = calculateFadeStyleForTierBreak(tierRef); // Only get borderTop
        Object.assign(tierRef.current.style, { borderTop });
      }
    });
  };

  const updateTierLabelFadeStyles = () => {
    tierLabelRefs.current.forEach((tierLabelRef) => {
      if (tierLabelRef.current) {
        const { color } = calculateFadeStyleForTierBreak(tierLabelRef); // Only get color
        Object.assign(tierLabelRef.current.style, { color });
      }
    });
  };

  const hexToRgb = (hex) => {
    const bigint = parseInt(hex.replace('#', ''), 16);
    return {
      r: (bigint >> 16) & 255,
      g: (bigint >> 8) & 255,
      b: bigint & 255,
    };
  };

  const blendColors = (color1, color2, weight) => {
    const rgb1 = hexToRgb(color1);
    const rgb2 = hexToRgb(color2);
    const r = Math.round(rgb1.r * (1 - weight) + rgb2.r * weight);
    const g = Math.round(rgb1.g * (1 - weight) + rgb2.g * weight);
    const b = Math.round(rgb1.b * (1 - weight) + rgb2.b * weight);
    return `rgb(${r}, ${g}, ${b})`;
  };

  const calculateFadeStyle = (rowRef) => {
    const fadeStart = isMobile ? 130 : 80;
    const fadeEnd = isMobile ? 160 : 110;
  
    const rect = rowRef.current?.getBoundingClientRect();
    if (!rect) return { opacity: 1 }; // Default style
  
    const distanceFromHeader = rect.top;
    const opacity =
      distanceFromHeader > fadeEnd
        ? 1
        : distanceFromHeader < fadeStart
        ? 0
        : (distanceFromHeader - fadeStart) / (fadeEnd - fadeStart);
  
    return {
      opacity
    };
  };

  const calculateFadeStyleForTierBreak = (tierRef) => {
    const fadeStart = isMobile ? 135 : 80;
    const fadeEnd = isMobile ? 155 : 110;
  
    const rect = tierRef.current?.getBoundingClientRect();
    if (!rect) {
      // Default style
      return {
        borderTop: '1px solid rgba(128, 128, 128, 1)',
        color: 'rgba(128, 128, 128, 1)',
      };
    }
  
    const distanceFromHeader = rect.top;
  
    // Adjust border opacity based on distance
    const borderOpacity =
      distanceFromHeader > fadeEnd
        ? 1
        : distanceFromHeader < fadeStart
        ? 0
        : (distanceFromHeader - fadeStart) / (fadeEnd - fadeStart);
  
    return {
      borderTop: `1px solid rgba(128, 128, 128, ${borderOpacity})`,
      color: `rgba(128, 128, 128, ${borderOpacity})`,
    };
  };

  const positions = includePicks
  ? ['C', '1B', '2B', '3B', 'SS', 'OF', 'SP', 'RP', 'PICK']
  : ['C', '1B', '2B', '3B', 'SS', 'OF', 'SP', 'RP', 'UT'];

  let globalTeamIndex = 1;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: 2,
        marginBottom: 4,
      }}
    >
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginBottom: 2,
          }}
        >
          <Tooltip
            title={
              <>
              <Typography sx={{ fontFamily: 'Avenir, Arial, sans-serif', fontSize: { xs: '10px', sm: '13px', md: '16px' } }}>
                {`• Positional ranks are displayed 1 through ${teams.flat().length}, where 1 is the strongest and ${teams.flat().length} is the weakest.`}
              </Typography>
              <Typography sx={{ fontFamily: 'Avenir, Arial, sans-serif', fontSize: { xs: '10px', sm: '13px', md: '16px' } }}>
                {`• Pick (draft capital) rank only considers 1st, 2nd and 3rd rounders for the next two years.`}
              </Typography>
              <Typography sx={{ fontFamily: 'Avenir, Arial, sans-serif', fontSize: { xs: '10px', sm: '13px', md: '16px' } }}>
                {`• NR (Not Ranked) indicates a 0 value at that position.`}
              </Typography>
              </>
            }
            open={tooltipOpen}
            onClose={handleTooltipClose}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            arrow
          >
            <Box
              onClick={handleTooltipToggle}
              sx={{
                ml: 'auto',
                backgroundColor: colors.primary400,
                minWidth: 0,
                width: { xs: 20, sm: 20, md: 30 },
                height: { xs: 20, sm: 20, md: 30 },
                borderRadius: '50%',
                padding: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: { xs: '.7rem', sm: '.8rem', md: '.9rem' },
                fontFamily: 'Avenir, Arial, sans-serif',
                color: 'white',
                cursor: 'pointer',
                fontWeight: 'bold'
              }}
            >
              ?
            </Box>
          </Tooltip>
        </Box>
        <Grid
          container
          spacing={0.5}
          columns={13}
          sx={{ position: 'sticky', top: isMobile ? 115 : 50, zIndex: 200, backgroundColor: 'transparent', paddingBottom: 1 }}
        >
          <Grid item xs={4}>
            <Grid container spacing={0.5}>
              <Grid item xs={3} sx={{ ...styles.positionContainer }}>
                <Paper
                  sx={{
                    ...styles.positionCard,
                    border: '1px solid',
                    backgroundColor: colors.primary400,
                    borderColor: colors.primary400,
                  }}
                >
                  <Box>
                    <Typography sx={{ ...styles.text, color: 'white' }}>Ovr</Typography>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={9}>{/* leave blank */}</Grid>
            </Grid>
          </Grid>
          {positions.map((position) => (
            <Grid item xs={1} key={position} sx={styles.positionContainer}>
              <Paper
                sx={{
                  ...styles.positionCard,
                  border: '1px solid',
                  backgroundColor: colors[position],
                  borderColor: colors[position],
                }}
              >
                <Box>
                  <Typography sx={{ ...styles.text }}>{position}</Typography>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>

        {teams.map((tier, tierIndex) => {
          const tierRef = tierRefs.current[tierIndex];
          const tierLabelRef = tierLabelRefs.current[tierIndex];

          return (
            <Box key={`tier-${tierIndex}`} ref={tierRef} sx={styles.tierBreak}>
              <Typography ref={tierLabelRef} sx={styles.tierLabel}>{`Tier ${tierIndex + 1}`}</Typography>
              {tier.map((team) => {
                const refIndex = globalTeamIndex - 1;
                const rowRef = rowRefs.current[refIndex];

                return (
                  <Grid
                    container
                    columns={13}
                    spacing={0.5}
                    alignItems="stretch"
                    key={team.teamId}
                    ref={rowRef}
                    sx={styles.gridRow}
                  >
                    <Grid item xs={4} sx={{ display: 'flex', alignItems: 'stretch' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <Box sx={styles.icon}>{globalTeamIndex++}</Box>
                        <Paper
                          sx={{
                            ...styles.row,
                            height: '100%',
                          }}
                        >
                          <Link to={`/league/${leagueId}/team/${team.teamId}`} 
                            style={{ 
                              textDecoration: 'underline', 
                              color: 'inherit',
                              maxWidth: '85%',
                            }}>
                            <Typography
                              sx={{
                                ...styles.text,
                                width: '100%',
                                marginLeft: { xs: '16px', sm: '20px', md: '24px' },
                              }}
                            >
                              {team.teamName}
                            </Typography>
                          </Link>
                        </Paper>
                      </Box>
                    </Grid>
                    {positions.map((position) => {
                      const rank = team.rankings[position] || '-';
                      const normalizedRank = (rank - 1) / (teams.flat().length - 1);
                      const opacity = 1 - 0.7 * normalizedRank;

                      const blendedColor = blendColors(
                        colors.primary500,
                        colors.secondary200,
                        normalizedRank
                      );

                      return (
                        <Grid
                          item
                          xs={1}
                          key={position}
                          sx={styles.positionContainer}
                        >
                          <Paper
                            sx={{
                              ...styles.positionCard,
                              height: '100%',
                              border: '1px solid',
                              backgroundColor: rank === -1 ? '#E0E0E0' : alpha(blendedColor, opacity),
                              borderColor: rank === -1 ? '#E0E0E0' : alpha(blendedColor, opacity),
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Box>
                              <Typography
                                sx={{
                                  ...styles.text,
                                  color: rank === -1 ? 'grey' : 'black',
                                }}
                              >
                                {rank === -1 ? 'NR' : rank}
                              </Typography>
                            </Box>
                          </Paper>
                        </Grid>
                      );
                    })}
                  </Grid>
                );
              })}
            </Box>
          )
        })}
      </Box>
    </Box>
  );
};

export default PositionRankingsTable;
