import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  IconButton,
  Card,
  CardContent,
  useTheme,
} from '@mui/material';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MiniPlayerChart from './MiniPlayerChart';
import PositionBox from '../../PositionBox/PositionBox';
import colors from '../../../config/colors';
import { useLeagueBreakdown } from '../../../hooks/LeagueContext';

const styles = {
  icon: {
    width: { xs: '22px', sm: '25px', md: '28px' },
    height: { xs: '22px', sm: '25px', md: '28px' },
    borderRadius: '20%',
    backgroundColor: colors.primary400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: { xs: '0.8rem', sm: '.9rem', md: '1rem' },
    color: 'white',
    fontWeight: 600,
    flexShrink: 0,
    zIndex: 100,
    fontFamily: 'Avenir, Arial, sans-serif',
    position: 'relative',
  },
  row: {
    border: '1px solid',
    backgroundColor: colors.secondary100,
    borderColor: colors.secondary500,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1,
    marginLeft: { xs: '-10px', sm: '-12px', md: '-14px' },
    position: 'relative',
    zIndex: 99,
    width: '100%',
    minWidth: 0,
  },
  text: {
    fontWeight: 600,
    color: 'black',
    fontSize: { xs: '0.8rem', sm: '.9rem', md: '1rem' },
    fontFamily: 'Avenir, Arial, sans-serif',
    maxWidth: '100%',
    p: { xs: 0.7, sm: 1 },
  },
  textSecondary: {
    fontWeight: 500,
    color: 'text.secondary',
    fontSize: { xs: '0.8rem', sm: '.9rem', md: '1rem' },
    fontFamily: 'Avenir, Arial, sans-serif',
    p: 1,
  },
};

const getRankSuffix = (rank) => {
  if (rank % 100 >= 11 && rank % 100 <= 13) {
    return 'th';
  }
  switch (rank % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

const StartingLineupList = ({ startingLineup, rank }) => {
  const [showAllPlayers, setShowAllPlayers] = useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { teams } = useLeagueBreakdown();

  const rankSuffix = getRankSuffix(rank);

    // Define the desired key order
    const keyOrder = ["C", "1B", "2B", "3B", "SS", "OF", "UT", "SP", "RP"];

    // Sort the positions based on the defined key order
    const sortedPositions = Object.entries(startingLineup.positionValueLeaders).sort(
      ([positionA], [positionB]) => keyOrder.indexOf(positionA) - keyOrder.indexOf(positionB)
    );

    const positionsToDisplay = showAllPlayers ? sortedPositions : sortedPositions.slice(0, 5);


  useEffect(() => {
    setShowAllPlayers(false);
  }, [startingLineup.positionValueLeaders]);

  return (
    <Card sx={{ my: 2, border: `1px solid ${colors.secondary500}`, paddingBottom: 0 }}>
      <CardContent sx={{ '&:last-child': { paddingBottom: 0 } }}>
      <Typography
        component="div"
        sx={{
            fontFamily: 'Avenir, Arial, sans-serif',
            fontWeight: 'bold',
            paddingLeft: '6px',
            fontSize: { xs: 18, sm: 22, md: 28 },
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        }}
        >
        Win Now
        <Typography
            component="span"
            sx={{
            fontFamily: 'Avenir, Arial, sans-serif',
            fontWeight: 'normal',
            fontSize: { xs: 14, sm: 16, md: 18 },
            color: colors.grey500,
            }}
        >
            Ovr <span style={{ fontWeight: 'bold' }}>{rank}{rankSuffix}</span>
            <span
            style={{
                fontSize: '0.8em',
                color: colors.grey400,
            }}
            >
            /{teams.flat().length}
            </span>
        </Typography>
        <Typography
            component="span"
            sx={{
            fontFamily: 'Avenir, Arial, sans-serif',
            fontWeight: 'normal',
            fontSize: { xs: 14, sm: 16, md: 18 },
            color: colors.grey500,
            }}
        >
            Avg age <span style={{ fontWeight: 'bold' }}>{startingLineup.averageAge.toFixed(2)}y</span>
        </Typography>
        </Typography>
        <Typography
            sx={{
              fontFamily: 'Avenir, Arial, sans-serif',
              fontWeight: 'normal',
              fontSize: { xs: 12, sm: 14, md: 16 },
              color: colors.grey500,
              paddingLeft: '6px',
            }}
          >
            Starting Lineup
          </Typography>
  
        {positionsToDisplay.map(([position, queue]) => (
          <Box key={position} sx={{ mt: { xs: 1.5, sm: 2 } }}>
            <Grid container spacing={{ xs: 1.5, sm: 2 }} style={{ display: 'block' }}>
                {Array.from(queue).length === 0 && (
                    <Typography sx={{ color: 'gray', fontSize: {xs: '.7rem', sm: '.8rem', md: '0.9rem'}, fontFamily: 'Avenir, Arial, sans-serif', textAlign: 'center' }}>
                        No players available
                    </Typography>
                )}
              {Array.from(queue)
                .sort((a, b) => b.value - a.value)
                .map((player, index) => (
                <Grid item xs={12} key={`${position}-${player.id}`} 
                    sx={{ 
                        display: 'flex', 
                        alignItems: 'stretch',
                    }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Box sx={{ ...styles.icon, backgroundColor: colors[position], fontSize: { xs: '0.7rem', sm: '.8rem', md: '.9rem' } }}>{position}</Box>
                    <Paper sx={{ ...styles.row, height: '100%', width: '100%' }}>
                      <Grid container>
                        <Grid item xs={5} sm={3.5} sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography
                            component={Link}
                            to={`/player/${player.id}`}
                            sx={{
                              ...styles.text,
                              marginLeft: { xs: '10px', sm: '12px', md: '14px' },
                            }}
                          >
                            {player.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={3.5} sx={{ display: 'flex', alignItems: 'center' }}>
                            {player.assetType === 'PLAYER' ? (
                              <Typography
                                sx={{
                                  ...styles.textSecondary,
                                  marginRight: 1,
                                }}
                              >
                                {player.positions && player.positions.map((position, index) => <PositionBox key={`${player.id}-${position}`} position={position} rank={player.positionRanks[position]} />)}
                              </Typography>
                            ) : (
                              <Typography
                                sx={{
                                  ...styles.textSecondary,
                                  marginRight: 1,
                                }}
                              >
                                <PositionBox key={index} position="PICK" />
                              </Typography>
                            )}
                        </Grid>
                        {!isSmallScreen && (<Grid item xs={3} sm={2} sx={{ display: 'flex', alignItems: 'center' }}>
                            {player.age && (
                              <Typography sx={styles.textSecondary}>{player.age}y</Typography>
                            )}
                          </Grid>
                        )}
                        <Grid item xs={3.5} sm={3} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                          <MiniPlayerChart valueHistory={player.valueHistory30Days} defaultValue={100} />
                          <Typography sx={{ ...styles.text, marginRight: 1 }}>
                            {player.value && player.value.toLocaleString()}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}
  
        {positionsToDisplay.length > 5 ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 1 }}>
                <IconButton
                onClick={() => setShowAllPlayers(!showAllPlayers)}
                sx={{
                    color: colors.primary400,
                    '&:hover': { color: colors.primary300 },
                }}
                >
                {showAllPlayers ? <KeyboardArrowUpIcon fontSize="large" /> : <KeyboardArrowDownIcon fontSize="large" />}
                </IconButton>
            </Box>
        ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 1 }}>
                <IconButton
                onClick={() => setShowAllPlayers(!showAllPlayers)}
                sx={{
                    color: colors.primary400,
                    '&:hover': { color: colors.primary300 },
                }}
                >
                {showAllPlayers ? <KeyboardArrowUpIcon fontSize="large" /> : <KeyboardArrowDownIcon fontSize="large" />}
                </IconButton>
            </Box>
        )}

      </CardContent>
    </Card>
  );
  
};

export default StartingLineupList;
