import React from 'react';
import { Paper, Typography } from '@mui/material';
import colors from '../../config/colors';

const HorizontalPlayerCard = ({ player, highlighted }) => (
  <Paper
    sx={{
      py: { xs: 0.75, sm: 1, md: 1.25 },
      px: { xs: 0.75, sm: 1, md: 1.25 },
      border: '1px solid',
      backgroundColor: highlighted ? colors.secondary200 : colors.secondary100,
      borderColor: colors.secondary500,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexGrow: 1,
      marginLeft: { xs: -1.85, sm: -2.1, md: -2.3 },
      minWidth: '80px',
    }}
  >
    <Typography
      sx={{
        fontWeight: 600,
        color: 'black',
        paddingLeft: { xs: '5px', sm: '6px', md: '7px' },
        fontSize: { xs: '0.55rem', sm: '.55rem', md: '.9rem' },
        fontFamily: 'Avenir, Arial, sans-serif',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {player.name}
    </Typography>
  </Paper>
);

export default HorizontalPlayerCard;
