import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  CircularProgress,
  Alert,
  Box,
  Button,
  Stack,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography,
  Collapse,
  IconButton,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HistoryIcon from '@mui/icons-material/History';
import { useLeagueBreakdown } from '../../hooks/LeagueContext';
import Title from '../Title/Title';
import HeadWrapper from '../HeadWrapper/HeadWrapper';
import PositionRankingsTable from './PositionRankingsTable';
import LeagueViewNavigation from './LeagueViewNavigation';
import logo from '../../assets/fullNameLogo.png';

const LeagueView = () => {
  const { leagueId: id } = useParams();
  const navigate = useNavigate();

  const {
    teams,
    farmTeams,
    winNowTeams,
    loading,
    badRequestError,
    error,
    badRequestLeagueId,
    setLeague,
  } = useLeagueBreakdown();

  const [instructionsExpanded, setInstructionsExpanded] = useState(false);
  const [leagueId, setLeagueId] = useState(id || '');
  const [view, setView] = useState('teams');
  const [lastUsedLeagueIds, setLastUsedLeagueIds] = useState([]);
  const [copiedLeagueId, setCopiedLeagueId] = useState(null);

  const handleViewChange = (event) => {
    setView(event.target.value);
  };

  const handleSubmit = async () => {
    const success = await setLeague(leagueId);
    if (success) {
      const updatedLeagueIds = [leagueId, ...lastUsedLeagueIds.filter((id) => id !== leagueId)].slice(0, 3);
      localStorage.setItem('lastUsedLeagueIds', JSON.stringify(updatedLeagueIds));
      setLastUsedLeagueIds(updatedLeagueIds);
      navigate(`/league/${leagueId}/rankings`);
    }
  };

  const handleCopyLeagueId = (id) => {
    navigator.clipboard.writeText(id);
    setCopiedLeagueId(id);

    // Reset the icon to the default state after 2 seconds
    setTimeout(() => setCopiedLeagueId(null), 3000);
  };

  useEffect(() => {
    const savedLeagueIds = JSON.parse(localStorage.getItem('lastUsedLeagueIds')) || [];
    setLastUsedLeagueIds(savedLeagueIds);

    if (id) {
      setLeagueId(id);
      setLeague(id);
    } else {
      setView('teams');
      setInstructionsExpanded(false);
    }
  }, [id, setLeague]);

  const displayInstructions = () => (
    <Box
      sx={{
        padding: 2,
        backgroundColor: '#f9f9f9',
        borderRadius: 1,
        maxWidth: '100%',
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
        width: '90%',
        margin: '0 auto',
      }}
    >
      <Typography variant="body2" sx={{ fontFamily: 'Avenir, Arial, sans-serif', marginBottom: 1 }}>
        Navigate to your Fantrax league on an internet browser. Look at the URL in your browser's address bar. The League ID is the part after <code>league/</code>.
      </Typography>
      <Typography variant="body2" sx={{ fontFamily: 'Avenir, Arial, sans-serif' }}>
        For example: In the URL <code>https://www.fantrax.com/fantasy/league/nfxjaho3p8ys6gqh/home</code>, the League ID is <code>nfxjaho3p8ys6gqh</code>.
      </Typography>
    </Box>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          maxWidth: '800px',
          width: '100%',
        }}
      >
        <HeadWrapper
          title="Dynasty Baseball League Power Rankings"
          description="Enter your Fantrax League ID and see real-time power rankings, based on crowd-sourced player values."
          image={logo}
        />
        {error && (
          <Alert severity="error" sx={{ marginBottom: 2, fontFamily: 'Avenir, Arial, sans-serif' }}>
            Error retrieving league power rankings. Please check your internet connection or try again later.
          </Alert>
        )}
        <Title
          pageName="League Power Rankings"
          extraText="Enter your Fantrax League ID to view your league's power rankings! All rankings are calculated using our crowd-sourced player and pick values."
          showPickerLink={true}
          showRankingsLink={false}
        />
        {teams.length === 0 && (
          <Box sx={{ padding: 2, maxWidth: 600, margin: '0 auto' }}>
            <Stack spacing={2}>
              <TextField
                id="league-id-input"
                name="leagueId"
                label="Fantrax League ID"
                variant="outlined"
                value={leagueId}
                onChange={(e) => setLeagueId(e.target.value)}
                fullWidth
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={loading || !leagueId || leagueId.length === 0}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
              </Button>
              {lastUsedLeagueIds.length > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 1,
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 1,
                    }}
                  >
                    <IconButton
                      size="small"
                      disabled
                      sx={{ marginLeft: '4px',
                        padding: '4px',
                        color: 'grey',
                        '& .MuiSvgIcon-root': {
                          color: 'grey',
                        },
                      }}
                    >
                      <HistoryIcon fontSize="small" />
                    </IconButton>
                    {lastUsedLeagueIds.map((id, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          padding: '4px 8px',
                          backgroundColor: '#f0f0f0',
                          borderRadius: '4px',
                          border: '1px solid #ccc',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleCopyLeagueId(id)}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: 'Avenir, Arial, sans-serif',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {id}
                        </Typography>
                        <IconButton
                          size="small"
                          sx={{
                            padding: '4px',
                            transition: 'transform 0.4s ease, opacity 0.4s ease',
                            transform: copiedLeagueId === id ? 'scale(1.2)' : 'scale(1)',
                            opacity: copiedLeagueId === id ? 1 : 0.8,
                          }}
                        >
                          {copiedLeagueId === id ? (
                            <CheckIcon
                              fontSize="small"
                              sx={{
                                transition: 'opacity 0.4s ease',
                              }}
                            />
                          ) : (
                            <ContentCopyIcon
                              fontSize="small"
                              sx={{
                                color: 'gray',
                                transition: 'opacity 0.4s ease',
                              }}
                            />
                          )}
                        </IconButton>
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '2px' }}>
                <Button
                  onClick={() => setInstructionsExpanded(!instructionsExpanded)}
                  startIcon={
                    <ExpandMoreIcon
                      sx={{ transform: instructionsExpanded ? 'rotate(180deg)' : 'rotate(0deg)' }}
                    />
                  }
                >
                  <Typography sx={{ fontFamily: 'Avenir, Arial, sans-serif', fontSize: { xs: '.7rem', sm: '.8rem', md: '.9rem' } }}>
                    {instructionsExpanded ? 'Hide' : 'Where do I find my League ID?'}
                  </Typography>
                </Button>
                <Collapse in={instructionsExpanded} timeout="auto" unmountOnExit>
                  <Box sx={{ marginTop: '10px', width: '100%' }}>{displayInstructions()}</Box>
                </Collapse>
              </Box>
              {badRequestError && (
                <Alert severity="error" sx={{ my: 2 }}>
                  Unable to pull rosters for league [{badRequestLeagueId}]. Check our instructions in "Where do I find my League ID?" and try again.
                </Alert>
              )}
            </Stack>
          </Box>
        )}
        {teams.length !== 0 && (
          <>
            <LeagueViewNavigation leagueId={leagueId} />
            <Box sx={{ padding: 1 }}>
            <FormControl fullWidth sx={{ marginBottom: { xs: 1, sm: 2, md: 3 } }}>
              <InputLabel
                id="view-select-label"
                sx={{
                  fontFamily: 'Avenir, Arial, sans-serif',
                  fontSize: { xs: '.8rem', sm: '.95rem', md: '1.05rem' },
                }}
              >
                Rankings
              </InputLabel>
              <Select
                id="rankings-view-select"
                name="view"
                labelId="view-select-label"
                value={view}
                onChange={handleViewChange}
                label="Rankings"
                sx={{
                  fontFamily: 'Avenir, Arial, sans-serif',
                  fontWeight: '500',
                  fontSize: { xs: '.8rem', sm: '.95rem', md: '1.05rem' },
                  '& .MuiSelect-select': {
                    padding: { xs: '8px 10px', sm: '10px 12px', md: '12.5px 14px' },
                  },
                }}
              >
                <MenuItem
                  value="teams"
                  sx={{
                    fontFamily: 'Avenir, Arial, sans-serif',
                    fontWeight: '500',
                    fontSize: { xs: '.8rem', sm: '.95rem', md: '1.05rem' },
                    minHeight: { xs: '36px', sm: '42px', md: '48px' },
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box>Full Roster</Box>
                  <Typography
                    variant="caption"
                    sx={{
                      color: 'text.secondary',
                      fontSize: { xs: '0.6rem', sm: '0.7rem', md: '0.8rem' },
                      fontFamily: 'Avenir, Arial, sans-serif',
                    }}
                  >
                    MLB ∙ MiLB ∙ Draft Capital
                  </Typography>
                </MenuItem>
                <MenuItem
                  value="farmTeams"
                  sx={{
                    fontFamily: 'Avenir, Arial, sans-serif',
                    fontWeight: '500',
                    fontSize: { xs: '.8rem', sm: '.95rem', md: '1.05rem' },
                    minHeight: { xs: '36px', sm: '42px', md: '48px' },
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box>Farm System</Box>
                  <Typography
                    variant="caption"
                    sx={{
                      color: 'text.secondary',
                      fontSize: { xs: '0.6rem', sm: '0.7rem', md: '0.8rem' },
                      fontFamily: 'Avenir, Arial, sans-serif',
                    }}
                  >
                    MiLB ∙ Draft Capital
                  </Typography>
                </MenuItem>
                <MenuItem
                  value="winNowTeams"
                  sx={{
                    fontFamily: 'Avenir, Arial, sans-serif',
                    fontWeight: '500',
                    fontSize: { xs: '.8rem', sm: '.95rem', md: '1.05rem' },
                    minHeight: { xs: '36px', sm: '42px', md: '48px' },
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box>Win Now</Box>
                  <Typography
                    variant="caption"
                    sx={{
                      color: 'text.secondary',
                      fontSize: { xs: '0.6rem', sm: '0.7rem', md: '0.8rem' },
                      fontFamily: 'Avenir, Arial, sans-serif',
                    }}
                  >
                    MLB ∙ C, 1B, 2B, 3B, SS, 3 OF, 5 SP, 3 RP, 2 UT
                  </Typography>
                </MenuItem>
              </Select>
            </FormControl>
            <PositionRankingsTable
              teams={view === 'teams' ? teams : view === 'farmTeams' ? farmTeams : winNowTeams}
              leagueId={leagueId}
              includePicks={view !== 'winNowTeams'}
            />
          </Box>
        </>
      )}
    </Box>
    </Box>
  );
};

export default LeagueView;
