import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Card, CardContent, useMediaQuery } from '@mui/material';
import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import colors from '../../../config/colors';

const PlayerValuesChart = ({ averagePlayerValues, team }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  let fontSize;
  if (isSmallScreen) {
    fontSize = 10;
  } else if (isMediumScreen) {
    fontSize = 12;
  } else {
    fontSize = 14;
  }

  let chartHeight;
  if (isSmallScreen) {
    chartHeight = 210;
  } else if (isMediumScreen) {
    chartHeight = 260;
  } else {
    chartHeight = 320;
  }

  let xAxisLabelTop;
  if (isSmallScreen) {
    xAxisLabelTop = 185;
  } else if (isMediumScreen) {
    xAxisLabelTop = 240;
  } else {
    xAxisLabelTop = 300;
  }

  const data = averagePlayerValues.map((value, index) => ({
    index,
    averageValue: value,
    teamValue: team.players[index]?.value || 0,
  }));

  const minY = Math.min(...averagePlayerValues, ...team.players.map((p) => p?.value || 0));
  const maxY = Math.max(...averagePlayerValues, ...team.players.map((p) => p?.value || 0));
  const midY = Math.min(
    Math.max(...averagePlayerValues),
    Math.max(...team.players.map((p) => p?.value || 0))
  );

  return (
    <Box position="relative">
      <Card sx={{ my: 2, border: `1px solid ${colors.secondary500}`, paddingBottom: 0 }}>
        <CardContent
          sx={{
            '&:last-child': {
              paddingBottom: 2,
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Avenir, Arial, sans-serif',
              fontWeight: 'bold',
              paddingLeft: '6px',
              marginBottom: 2,
              fontSize: { xs: 18, sm: 22, md: 28 }
            }}
          >
            Roster Value Distribution
          </Typography>
          <Box position="relative">
            <Typography
              sx={{
                fontFamily: 'Avenir, Arial, sans-serif',
                position: 'absolute',
                top: xAxisLabelTop,
                left: '20px',
                color: colors.textPrimary,
                fontSize: { xs: 12, sm: 14, md: 16},
              }}
            >
              Top of Roster
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Avenir, Arial, sans-serif',
                position: 'absolute',
                top: xAxisLabelTop,
                right: '30px',
                color: colors.textPrimary,
                fontSize: { xs: 12, sm: 14, md: 16},
              }}
            >
              Bottom of Roster
            </Typography>
            <ResponsiveContainer width="100%" height={chartHeight}>
                <AreaChart data={data} margin={{ top: 10, right: 0, left: -15, bottom: 10 }}>
                    <XAxis
                    dataKey="index"
                    tick={false}
                    axisLine={{ stroke: colors.textPrimary }}
                    />
                    <YAxis
                    domain={[minY, maxY]}
                    ticks={[minY, midY, maxY]}
                    tick={({ x, y, payload }) => (
                        <text
                        x={x}
                        y={y}
                        textAnchor="end"
                        fontFamily="Avenir, Arial, sans-serif"
                        fontSize={fontSize}
                        fontWeight="bold"
                        fill={colors.textPrimary}
                        >
                        {payload.value.toLocaleString()}
                        </text>
                    )}
                    tickLine={false}
                    axisLine={{ stroke: colors.textPrimary }}
                    />
                    <Area
                        type="monotone"
                        dataKey="averageValue"
                        name="Average Player Value"
                        stroke="#B0B0B0"
                        fill="#D3D3D3"
                        strokeWidth={2}
                        fillOpacity={0.6}
                    />
                    <Area
                        type="monotone"
                        dataKey="teamValue"
                        name="Team Player Value"
                        stroke={colors.primary400}
                        fill={colors.primary300}
                        strokeWidth={2}
                        fillOpacity={0.6}
                    />
                </AreaChart>
            </ResponsiveContainer>
          </Box>
          <Box display="flex" justifyContent="center" mt={isSmallScreen ? 1 : 2}>
            <Box display="flex" alignItems="center" mr={4}>
              <Box
                sx={{
                  width: { xs: 12, sm: 14, md: 16 },
                  height: { xs: 12, sm: 14, md: 16 },
                  backgroundColor: colors.primary400,
                  borderRadius: '50%',
                  marginRight: 1,
                }}
              />
              <Typography
                variant="body2"
                sx={{
                  fontFamily: 'Avenir, Arial, sans-serif',
                  fontSize: { xs: 12, sm: 14, md: 16 }
                }}
              >
                {`${team.teamName}`}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Box
                sx={{
                    width: { xs: 12, sm: 14, md: 16 },
                    height: { xs: 12, sm: 14, md: 16 },
                    backgroundColor: '#B0B0B0',
                    borderRadius: '50%',
                    marginRight: 1,
                }}
              />
              <Typography
                variant="body2"
                sx={{
                  fontFamily: 'Avenir, Arial, sans-serif',
                  fontSize: { xs: 12, sm: 14, md: 16 }
                }}
              >
                League Average
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PlayerValuesChart;
