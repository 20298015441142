import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

const LeagueViewNavigation = ({ leagueId }) => {
    const location = useLocation();
    const navigate = useNavigate();

        // Determine initial state based on the current URL path
        const getInitialView = () => {
            if (location.pathname.includes('/rankings')) return 'rankings';
            if (location.pathname.includes(`/team`)) return 'team';
            return 'rankings'; // Default fallback
        };
    
        const [view, setView] = useState(getInitialView);

    const handleViewChange = (selectedView) => {
        setView(selectedView);
        const path = selectedView === 'rankings' ? `/league/${leagueId}/rankings` : `/league/${leagueId}/team`;
        navigate(path);
    };

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ my: 2 }}
        >
            <Typography
                onClick={() => handleViewChange('rankings')}
                sx={{
                    cursor: 'pointer',
                    color: view === 'rankings' ? 'primary.main' : 'text.secondary',
                    fontWeight: view === 'rankings' ? 'bold' : 'normal',
                    marginRight: 1,
                    fontFamily: 'Avenir, Arial, sans-serif',
                    fontSize: { xs: 14, sm: 16, md: 18}
                }}
            >
                Power Rankings
            </Typography>
            <Typography sx={{ color: 'text.secondary', marginX: 1 }}>•</Typography>
            <Typography
                onClick={() => handleViewChange('team')}
                sx={{
                    cursor: 'pointer',
                    color: view === 'team' ? 'primary.main' : 'text.secondary',
                    fontWeight: view === 'team' ? 'bold' : 'normal',
                    marginLeft: 1,
                    fontFamily: 'Avenir, Arial, sans-serif',
                    fontSize: { xs: 14, sm: 16, md: 18}
                }}
            >
                Rosters
            </Typography>
        </Box>
    );
};

export default LeagueViewNavigation;
