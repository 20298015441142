import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Button, Paper, Grid, IconButton, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CallMadeIcon from '@mui/icons-material/CallMade';
import DownloadIcon from '@mui/icons-material/Download';
import FileSaver from 'file-saver';
import colors from '../../config/colors';
import PositionBox from '../PositionBox/PositionBox';

const styles = {
  icon: {
    minWidth: { xs: '19px', sm: '24px', md: '27px' },
    width: 'fit-content',
    padding: '0 1px',
    height: { xs: '21px', sm: '26px', md: '29px' },
    borderRadius: '20%',
    backgroundColor: colors.primary400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: { xs: '0.65rem', sm: '.8rem', md: '1rem' },
    color: 'white',
    fontWeight: 600,
    flexShrink: 0,
    zIndex: 100,
    fontFamily: 'Avenir, Arial, sans-serif',
    position: 'relative',
  },
  row: {
    border: '1px solid',
    backgroundColor: colors.secondary100,
    borderColor: colors.secondary500,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1,
    marginLeft: { xs: '-10px', sm: '-12px', md: '-14px' },
    position: 'relative',
    zIndex: 99,
    width: '100%',
    minWidth: 0,
  },
  text: {
    fontWeight: 600,
    color: 'black',
    fontSize: { xs: '0.7rem', sm: '.8rem', md: '1rem' },
    fontFamily: 'Avenir, Arial, sans-serif',
    maxWidth: '100%',
  },
  textSecondary: {
    fontWeight: 500,
    color: 'text.secondary',
    fontSize: { xs: '0.7rem', sm: '.8rem', md: '1rem' },
    fontFamily: 'Avenir, Arial, sans-serif',
  },
  columnElement: {
    p: { xs: 0.7, sm: 1 },
  },
  italicText: {
    fontWeight: 500,
    color: 'text.secondary',
    fontSize: { xs: '0.7rem', sm: '.8rem', md: '1rem' },
    fontFamily: 'Avenir, Arial, sans-serif',
    fontStyle: 'italic'
  }
};

const PlayerList = ({ players, isFiltered, isSearched }) => {
  const [page, setPage] = useState(0);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const playerSpace = isSmallScreen ? 4 : 7;
    const teamSpace = 1.5;
    const ageSpace = 2;
    const positionSpace = isSmallScreen ? 2.5 : 3;
    const valueSpace = 2;

  useEffect(() => {
    setPage(0);
  }, [isFiltered, isSearched]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const renderLevel = (level) => {
    let levelText = level;
    if(level === 'HIGH_A') {
      levelText = 'A+';
    } else if(level === 'LOW_A') {
      levelText = 'A';
    } else if(level === 'ROOKIE_BALL') {
      levelText = 'ROK';
    }
    return levelText;
  }

  const handleDownloadCSV = () => {
    const headers = ['Rank', 'Name', 'Value', 'Age', 'Positions', 'Team', 'Level'];
    const csvRows = [headers.join(',')];

    players.forEach(player => {
      const row = [
        player.originalIndex + 1,
        `"${player.name || ''}"`,
        player.value || '',
        player.age || '',
        `"${player.positions?.join(', ') || ''}"`,
        `"${player.team || ''}"`,
        `"${renderLevel(player.level) || ''}"`
      ];
      csvRows.push(row.join(','));
    });

    const csvData = new Blob([csvRows.join('\n')], { type: 'text/csv;charset=utf-8;' });
    const fileName = isFiltered ? 'harryknowsball_filtered_players.csv' : 'harryknowsball_players.csv';
    FileSaver.saveAs(csvData, fileName);
  };

  const renderValueChangeIcon = (valueChange, mediumThreshold) => {
    const largeThreshold = mediumThreshold * 3;
    const Icon = Math.abs(valueChange) < mediumThreshold ? null : (valueChange > 0 ? (valueChange > largeThreshold ? ArrowDownwardIcon : CallReceivedIcon) : (valueChange < (largeThreshold * -1) ? ArrowUpwardIcon : CallMadeIcon));

    const color =
    valueChange > 0
      ? 'error.main' // Downward icons
      : 'success.main';

    return (
      Icon && <Icon sx={{ fontSize: { xs: '.9rem', sm: '1rem', md: '1.2rem'}, color: color }}/>
    );
  };  

  const renderPagination = () => (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px', marginTop: 2, marginLeft: { xs: '10px', sm: '12px', md: '14px' }, borderTop: '1px solid', borderColor: colors.greyLight }}>
      {/* Empty space on the left */}
      <Box sx={{ flex: 1 }} />
  
      {/* Centered Previous and Next buttons */}
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
        <Button
          onClick={() => handleChangePage(Math.max(0, page - 1))}
          disabled={page === 0}
          sx={{
            fontSize: { xs: '.75rem', sm: '.9rem', md: '1rem' },
            fontFamily: 'Avenir, Arial, sans-serif',
          }}
        >
          Previous
        </Button>
        <Button
          onClick={() =>
            handleChangePage(Math.min(Math.max(Math.ceil(players.length / 50 - 1), 1), page + 1))
          }
          disabled={page >= Math.ceil(players.length / 50) - 1}
          sx={{
            fontSize: { xs: '.75rem', sm: '.9rem', md: '1rem' },
            fontFamily: 'Avenir, Arial, sans-serif',
          }}
        >
          Next
        </Button>
      </Box>
  
      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton
          sx={{
            padding: 0,
            ml: 1,
          }}
          onClick={handleDownloadCSV} // Call the CSV download handler
        >
          <Box
            sx={{
              backgroundColor: colors.primary400,
              color: 'white',
              width: { xs: 30, sm: 34, md: 40 }, // Circle size
              height: { xs: 30, sm: 34, md: 40 },
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <DownloadIcon sx={{ fontSize: { xs: '1.1rem', sm: '1.25rem', md: '1.4rem' } }} />
          </Box>
        </IconButton>
      </Box>
    </Box>
  );  

  const rowsPerPage = 50;
  const currentPage = page;
  const start = currentPage * rowsPerPage;
  const end = Math.min(players.length, start + rowsPerPage);
  const playersForCurrentPage = players.slice(start, end);

  return (
    <Grid
      container
      spacing={{ xs: 1.5, sm: 2 }}
      style={{ display: 'block' }}
    >
        {/* Header Row */}
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box
            sx={{
              height: '100%',
              width: '100%',
              fontWeight: 'bold',
              marginLeft: 0,
              borderBottom: '1px solid', // Adds the underline
              borderColor: colors.greyLight, // Use theme's primary color (or any other color)
              backgroundColor: 'transparent', // Ensure no background color
              boxShadow: 'none', // Remove any shadow (if inherited from Paper)
            }}
          >
            <Grid container sx={{ paddingLeft: 2 }}>
              <Grid item xs={playerSpace}>
                <Typography
                  sx={{
                    ...styles.text,
                    ...styles.columnElement,
                    marginLeft: { xs: '10px', sm: '12px', md: '14px' },
                  }}
                >
                  Player
                </Typography>
              </Grid>
              {isSmallScreen && (
                <Grid item sm={teamSpace}>
                  <Typography
                    sx={{
                      ...styles.text,
                      ...styles.columnElement,
                    }}
                  >
                    Team
                  </Typography>
                </Grid>
              )}
              {isSmallScreen && (
                <Grid item sm={ageSpace}>
                  <Typography
                    sx={{
                      ...styles.text,
                      ...styles.columnElement,
                    }}
                  >
                    Age
                  </Typography>
                </Grid>
              )}
              <Grid item xs={positionSpace}>
                <Typography
                  sx={{
                    ...styles.text,
                    ...styles.columnElement,
                  }}
                >
                  Positions
                </Typography>
              </Grid>
              <Grid item xs={valueSpace}
                sx={{
                  display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                }}>
                <Typography
                  sx={{
                    ...styles.text,
                    ...styles.columnElement,
                    marginRight: 1,
                  }}
                >
                  Value
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      {playersForCurrentPage.map((player, index) => (
        <Grid
          item
          xs={12}
          key={`${player.id}-${index}`}
          sx={{ display: 'flex', alignItems: 'stretch' }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Box sx={styles.icon}>{player.originalIndex + 1}</Box>
            <Paper
              sx={{
                ...styles.row,
                height: '100%',
                width: '100%',
              }}
            >
              <Grid container>
                <Grid item xs={playerSpace}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                      marginLeft: { xs: '10px', sm: '12px', md: '14px' },
                      ...styles.columnElement,
                      px: 1,
                    }}
                  >
                    <Typography
                      component={Link} // Use the Link component as the base
                      to={`/player/${player.id}`}
                      sx={{
                        ...styles.text,
                        marginRight: 0
                      }}
                    >
                      {player.name}
                    </Typography>
                    {player.assetType === "PLAYER" && (
                      <>
                        <Typography 
                          component="span" 
                          color="text.secondary" 
                          sx={{ 
                            ...styles.italicText
                          }}
                        >
                          &nbsp;‧&nbsp;
                        </Typography>
                        <Typography 
                          component="span" 
                          color="text.secondary" 
                          sx={{ 
                            ...styles.italicText
                          }}
                        >
                          {renderLevel(player.level)}&nbsp;
                        </Typography>
                        {!isSmallScreen && (
                          <Typography 
                            component="span" 
                            color="text.secondary" 
                            sx={{ 
                              ...styles.italicText,
                            }}
                          >
                            ‧&nbsp;
                          </Typography>
                        )}
                        {!isSmallScreen && (
                          <Typography 
                            component="span" 
                            color="text.secondary" 
                            sx={{ 
                              ...styles.italicText
                            }}
                          >
                            {player.team}
                          </Typography>
                        )}
                      </>
                    )}
                  </Box>
                </Grid>
                {isSmallScreen && <Grid item sm={teamSpace}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    {player.assetType === 'PLAYER' && (
                      <Typography
                        sx={{
                          ...styles.textSecondary,
                          ...styles.columnElement,
                        }}
                      >
                        {player.team}
                      </Typography>
                    )}
                </Grid>}
                {isSmallScreen && <Grid item sm={ageSpace}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    {player.assetType === 'PLAYER' && (
                      <Typography
                        sx={{
                          ...styles.textSecondary,
                          ...styles.columnElement,
                        }}
                      >
                        {player.age}
                      </Typography>
                    )}
                </Grid>}
                <Grid item xs={positionSpace}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  {player.assetType === 'PLAYER' ? (
                    <Typography
                      sx={{
                        ...styles.textSecondary,
                        ...styles.columnElement,
                      }}
                    >
                      {player.positions && player.positions.map((position, index) => <PositionBox key={`${player.id}-${position}`} position={position} rank={player.positionRanks[position]} />)}
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        ...styles.textSecondary,
                        ...styles.columnElement,
                      }}
                    >
                      <PositionBox key={index} position="PICK" />
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  xs={valueSpace}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    >
                    {renderValueChangeIcon(player.valueChange30Days * -1, Math.max((player.value / 40), 10))}
                    <Typography
                      sx={{
                        ...styles.text,
                        ...styles.columnElement,
                        marginRight: 1,
                        color: player.value === 10000 ? colors.secondary600 : 'black'
                      }}
                    >
                      {player.value && player.value.toLocaleString()}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Grid>
      ))}
      {players && players.length > 0 ? (
        renderPagination()
      )
         : (
          <Typography sx={{ ...styles.text, padding: 1, textAlign: 'center' }}>
            No players match the selected filter.
          </Typography>
        )}
    </Grid>
  );
};

export default PlayerList;
