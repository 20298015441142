import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Box, Typography } from '@mui/material';
import SwipeVerticalIcon from '@mui/icons-material/SwipeVertical';
import ListIcon from '@mui/icons-material/List';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import InfoIcon from '@mui/icons-material/Info';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import GroupIcon from '@mui/icons-material/Group';
import { Link, useLocation } from 'react-router-dom';
import colors from '../../config/colors';
import logoImage from '../../assets/Slide1.png';

const drawerWidth = 240;

const Sidebar = () => {
  const location = useLocation();

  const isRouteActive = (route) => {
    return location.pathname.startsWith(route);
  };

  const renderSection = (title, items) => (
    <>
      <Box sx={{ padding: '10px 16px' }}>
        <Typography variant="h6" color={colors.primary400} style={{ fontFamily: 'Futura, sans-serif' }}>{title}</Typography>
      </Box>
      <List>
        {items.map(({ path, text, icon }, index) => (
          <ListItem 
            button 
            component={Link} 
            to={path} 
            key={text}
            sx={{
              backgroundImage: isRouteActive(path) ? `linear-gradient(to right, ${colors.secondary500}, transparent 120%)` : 'transparent',
              pl: 2,
              transform: 'scale(1)',
              transition: 'transform 0.3s ease-in-out, background-image 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.05)',
                backgroundImage: isRouteActive(path) ? `linear-gradient(to right, ${colors.secondary500}, transparent 120%)` : `linear-gradient(to right, ${colors.secondary500}, transparent 70%)`,
              },
            }}
          >
            <ListItemIcon style={{ color: colors.grey500 }}>{icon}</ListItemIcon>
            <ListItemText disableTypography primary={text} style={{ fontFamily: 'Futura, sans-serif', color: colors.grey500 }}/>
          </ListItem>
        ))}
      </List>
      <Divider />
    </>
  );

  const sections = [
    {
      title: 'Dynasty',
      items: [
        { path: '/picker', text: 'Picker', icon: <SwipeVerticalIcon /> },
        { path: '/rankings', text: 'Rankings', icon: <ListIcon /> },
        { path: '/calculator', text: 'Trade Calculator', icon: <ShuffleIcon /> },
        { path: '/league', text: 'League Power Rankings', icon: <GroupIcon /> },
        { path: '/activity', text: 'Activity Feed', icon: <DynamicFeedIcon /> },
      ]
    },
    {
      title: 'General',
      items: [
        { path: '/faq', text: 'FAQ', icon: <InfoIcon /> }
      ]
    }
  ];

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '20px',
        }}
      >
        <ListItem button component={Link} to="/" key="LandingPage-sidebar"   
          sx={{
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <img src={logoImage} alt="Logo" style={{ maxHeight: 90 }} />
        </ListItem>
      </Box>
      <Divider />
      {sections.map((section, index) => (
        <React.Fragment key={index}>
          {renderSection(section.title, section.items)}
        </React.Fragment>
      ))}
    </Drawer>
  );
};

export default Sidebar;
