import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Paper, Grid } from '@mui/material';
import colors from '../../config/colors';

const PlayerCard = ({ player }) => (
  <Paper
    sx={{
      py: { xs: 1.3, sm: 1.6, md: 1.8 },
      px: { xs: 1.3, sm: 1.6, md: 1.8 },
      border: '1px solid',
      backgroundColor: colors.secondary100,
      borderColor: colors.secondary500,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexGrow: 1,
      marginLeft: { xs: -2.5, sm: -2.75, md: -3 },
    }}
  >
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={7}>
        <Typography
          sx={{
            fontWeight: 600,
            color: 'black',
            paddingLeft: '3px',
            fontSize: { xs: '0.75rem', sm: '.95rem', md: '1.15rem' },
            fontFamily: 'Avenir, Arial, sans-serif',
            marginLeft: { xs: .75, sm: 1 }
          }}
          component={Link}  // Set the component prop to Link
          to={`/player/${player.playerId}`}  // Pass the link to prop
        >
          {player.name}
        </Typography>
      </Grid>
      <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Box>
          {player.assetType === 'PLAYER' ? (
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Typography variant="body2" color="text.secondary" sx={{ fontSize: { xs: '0.75rem', sm: '.95rem', md: '1.15rem'}, fontFamily: 'Avenir, Arial, sans-serif' }}>
                {player.positions && player.positions.join(", ")} ‧ {player.team} ‧ {player.age} { /* Asterisk */ }
              </Typography>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Typography variant="body2" color="text.secondary" sx={{ fontSize: { xs: '0.75rem', sm: '.95rem', md: '1.15rem' }, fontFamily: 'Avenir, Arial, sans-serif', }}>
                {player.assetType}
              </Typography>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  </Paper>
);

export default PlayerCard;
