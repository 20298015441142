import React from 'react';
import { Container, Typography, Box, Accordion, AccordionSummary, AccordionDetails, List } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Title from '../Title/Title';
import colors from '../../config/colors';

import HeadWrapper from '../HeadWrapper/HeadWrapper';

import logo from '../../assets/Slide1.png';

// Define a reusable style object for Typography components
const typographyStyles = {
  fontWeight: '400',
  fontFamily: 'Avenir, Arial, sans-serif',
  letterSpacing: '0.01em',
};

const faqs = {
  Picker: [
    {
      question: 'What is the Player Picker?',
      answer: 'The Player Picker helps us understand how users value different players. When you make a player selection, the involved players either gain value or lose value in our rankings based on your selection order. If you like our site, the best way to help contribute is by making player selections using this tool.'
    },
    {
      question: 'How does the 20 - 80 scale work?',
      answer: 'The 20 - 80 scale is based on the popular prospect grading system that assigns player values on a numeric scale: 20 being the worst and 80 the best. Similarly, when using the Player Picker, you will assign each of the four players a unique dynasty value grade between 20 and 80, with 20 being the worst and 80 the best among the group.!'
    }
  ],
  Rankings: [
    {
      question: 'How are player values determined?',
      answer: `HarryKnowsBall uses your inputs along with a super fancy, top secret algorithm to generate player values. We take into account things like previous value, comparison grade and relative position to ensure our rankings stay up to date and flexible. \nThe lower value segment of our player set (< 300 points) is determined based on Fantrax ownership percentage and not user selections. This section is meant to provide more options in our trade calculator and does not indicate consensus player values.`,
    },
    {
      question: 'What league format is used?',
      answer: `HarryKnowsBall assumes a 14 team league size using standard Fantrax Points scoring. Details include:`,
      additionalDetails: (
        <>
          <br />
          <Typography  sx={typographyStyles} variant="subtitle1" component="h3">Hitting</Typography>
          <ul>
            <li><Typography sx={typographyStyles}>Caught Stealing (CS): -1 point</Typography></li>
            <li><Typography sx={typographyStyles}>Doubles (2B): 2 points</Typography></li>
            <li><Typography sx={typographyStyles}>Home Runs (HR): 4 points</Typography></li>
            <li><Typography sx={typographyStyles}>Intentional Walks (IBB): 1 point</Typography></li>
            <li><Typography sx={typographyStyles}>Runs Batted In (RBI): 1 point</Typography></li>
            <li><Typography sx={typographyStyles}>Runs Scored (R): 1 point</Typography></li>
            <li><Typography sx={typographyStyles}>Singles (1B): 1 point</Typography></li>
            <li><Typography sx={typographyStyles}>Stolen Bases (SB): 2 points</Typography></li>
            <li><Typography sx={typographyStyles}>Triples (3B): 3 points</Typography></li>
            <li><Typography sx={typographyStyles}>Walks (BB): 1 point</Typography></li>
          </ul>
          <Typography  sx={typographyStyles} variant="subtitle1" component="h3">Pitching</Typography>
          <ul>
            <li>
              <Typography sx={typographyStyles}>Earned Runs Allowed (ER): Points vary by runs allowed per game (0: 7 points, 1: 5 points, 2: 3 points, 3: 2 points, 4: 1 point, ≥ 5: 0 points)</Typography>
            </li>
            <li>
              <Typography sx={typographyStyles}>Strikeouts Pitched (K): Points vary by strikeouts per game (0-5: 0 points, 6-12: 1-3 points incrementally, 13-18: 5 points, 19-26+: 7-10 points)</Typography>
            </li>
            <li><Typography sx={typographyStyles}>Wins (W): 3 points</Typography></li>
            <li><Typography sx={typographyStyles}>Save (SV): 3 points</Typography></li>
            <li>
              <Typography sx={typographyStyles}>Hits + Walks Allowed (H+BB): Points vary by total per game (0: 20 points, 1-2: 16-12 points, 3-5: 8-4 points, 6-10: 4-2 points, 11-12: 1 point, ≥13: 0 points)</Typography>
            </li>
          </ul>
        </>
      ),
    },
    {
      question: 'Why don\'t I see my players?',
      answer: `HarryKnowsBall aggregates player data from ownership percentages. If a lesser known player is not included please check back periodically as our player set refreshes weekly.`
    }
  ],
  'Trade Calculator': [
    {
      question: 'How should I use the Trade Calculator?',
      answer: `The Trade Calculator should be used as a tool for evaluating dynasty trade offers. It is meant to serve as a single data point rather than the entire evaluation. As always, know your league mates and understand how your unique league and scoring settings might sway value one way or another.`,
    },
    {
      question: 'What is package adjustment?',
      answer: `Package adjustment serves to penalize using multiple lower value players to acquire a single high value player. It puts a premium on higher value players.`,
    }
  ],
  'League Power Rankings': [
    {
      question: 'How are power rankings determined?',
      answer: 'League power rankings are determined by aggregating our crowd-sourced player and pick values for each team in a given league. We rank teams by total value, and create tiers based on value gaps. We also break down the value distribution by position to identify which areas are the strongest, and which areas are the weakest for each team.',
      additionalDetails: (
        <>
          <br />
          <Typography  sx={typographyStyles} variant="subtitle1" component="h3">Entire Roster</Typography>
          <ul>
            <li><Typography sx={typographyStyles}>Sums entire team value to create rankings and tiers</Typography></li>
          </ul>
          <Typography  sx={typographyStyles} variant="subtitle1" component="h3">Farm System</Typography>
          <ul>
            <li>
              <Typography sx={typographyStyles}>Sums value of all minor leaguers to create rankings and tiers</Typography>
            </li>
          </ul>
          <Typography  sx={typographyStyles} variant="subtitle1" component="h3">Win Now</Typography>
          <ul>
            <li>
              <Typography sx={typographyStyles}>Creates the best starting lineup from your available MLB players and sums the lineup value to create rankings and tiers</Typography>
            </li>
            <li>
              <Typography sx={typographyStyles}>Uses a standard [C, 1B, 2B, 3B, SS, 3 OF, 5 SP, 3 RP, 2 UT] lineup. Specific league lineup settings will be integrated in the future</Typography>
            </li>
            <li>
              <Typography sx={typographyStyles}>For players with multiple position eligibility, they will be used at the position to maximize total lineup value</Typography>
            </li>
            <li>
              <Typography sx={typographyStyles}>Two way players can be double counted, regular multi-position players will not be double counted</Typography>
            </li>
          </ul>
        </>
      ),
    },
    {
      question: 'Will there be future support for other league platforms?',
      answer: 'League Power Rankings only supports Fantrax at this time. However, there are plans to expand to other platforms in the near future. Keep an eye out for updates on this front.'
    },
    {
      question: 'Where do I find my Fantrax League ID?',
      answer: 'Navigate to your Fantrax league on a desktop browser. Look at the URL in your browser\'s address bar. The League ID is the part after league/. For example: In the URL https://www.fantrax.com/fantasy/league/nfxjaho3p8ys6gqh/home, the League ID is nfxjaho3p8ys6gqh.'
    },
  ],
  General: [
    {
      question: 'What\'s next?',
      answer: `Our team is continuously working on enhancements and will release new features on an ongoing basis. Please follow us on X at @HKBRankings to stay up to date. Some priorities include team and league evaluations, including more interactive injury and prospect filters, and integrating other analytics tools. Please contact us if you have any features you would like to see!`,
    },
    {
      question: 'Who is Harry?',
      answer: `Harry of HarryKnowsBall is indeed a dynasty baseball loving software engineer. He spends his free time watching his beloved Padres and drinking IPAs, win or lose. He has a dog named after Fernando Tatis Jr. and a strong distaste for Jon Heyman and Ken Rosenthal.`,
    },
    {
      question: 'Contact Info',
      answer: `To contact the HKB team please DM us on X at @HKBRankings or email us at harryknowsballteam@gmail.com`,
    }
  ],
};

const FAQSection = ({ title, faqs }) => (
  <>
    <Box sx={{ padding: '10px 16px' }}>
      <Typography variant="h6" color={colors.primary400} style={{ fontFamily: 'Futura, sans-serif' }}>{title}</Typography>
    </Box>
    <List>
      {faqs.map((faq, index) => (
        <Accordion 
          sx={{
            mb: 2,
            boxShadow: 'none', 
            borderBottom: '1px solid rgba(0, 0, 0, .125)', 
            '&:before': {
              display: 'none',
            },
            '&.Mui-expanded': {
              margin: 'auto',
            },
            '& .MuiAccordionSummary-root': {
              borderBottom: 0,
            },
            '& .MuiAccordionDetails-root': {
              borderTop: 0,
            }
          }} 
          key={index}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}a-content`} id={`panel${index}a-header`}>
            <Typography sx={{ ...typographyStyles, fontWeight: 500 }}>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={typographyStyles}>
              {faq.answer}
            </Typography>
              {faq.additionalDetails}
          </AccordionDetails>
        </Accordion>
      ))}
    </List>
  </>
);

const FAQ = () => {
  return (
    <>
      <HeadWrapper 
        title="Frequently Asked Questions" 
        description="Frequently asked questions for HarryKnowsBall." 
        image={logo} 
      />
      <Title pageName="FAQ" showPickerLink={true} showRankingsLink={false}  />
      <Container maxWidth="sm" sx={{ py: 0, display: 'flex', flexDirection: 'column', gap: 0 }}>
        {Object.entries(faqs).map(([title, faqItems]) => (
          <FAQSection key={title} title={title} faqs={faqItems} />
        ))}
      </Container>
    </>
  );
};

export default FAQ;
