import React from 'react';
import { Box } from '@mui/material';
import colors from '../../config/colors';

const PositionBox = ({ position, customStyles, rank }) => {

  return (
    <Box 
      component="span"
      sx={{ 
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: {
          xs: '1px',
          sm: '1.5px',
          md: '2px',
        },
        backgroundColor: colors[position] || colors.defaultPositionColor, 
        borderRadius: '4px', 
        fontSize: {
          xs: '0.65rem',
          sm: '0.65rem',
          md: '0.85rem',
        },
        color: 'white',
        padding: {
          xs: rank < 100 ? '2px 3px' : '2px 5px',
          sm: '3px 5px',
          md: '4px 8px',
        },
        whiteSpace: 'nowrap', // Prevents wrapping of text
        ...customStyles,
      }}
    >
      <span style={{ fontWeight: 600 }}>{position}</span>
      {rank && rank < 100 && (
        <span
          style={{
            fontSize: '0.85em',
            color: colors.grey400,
          }}
        >
          /{rank}
        </span>
      )}
    </Box>
  );
};

export default PositionBox;
